const data = [
	"Java",
	"Node",
	"React",
	"React Native",
	"Express",
	"Python",
	"C",
	"TypeScript",
	"Haskell",
	"Shell",
	"Mongo",
	"SQL"
];

export default data;
